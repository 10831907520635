.location-col-title {
    width: 90vw;
    margin-top: 15vh;
    height: 400px;
}

.location-col {
    width: 100vw;
    margin-top: 15vh;
}

.location-title {
    align-items: center;
    height: 15dvh;
    text-align: center;
    color: #E0E0E0;
    font-size: 3rem;
    font-weight: 600;
    font-style: italic;
    line-height: 3rem;
}

.absolute-pos {
    position: relative;
    z-index: 1;
}

.location-btns-row {
    bottom: 25vh;
    width: 700px;
    margin-left: calc(50% - 350px) !important;
}

.location-title-row {
    bottom: 90dvh;
    height: 10dvh;
}


.location-btn {
    text-decoration: none;
    margin-top: 3vh;
    width: 160px;
    border: none;
    border-bottom: 2px solid #FFC107;
    padding: 10px 20px;
    font-size: 1.8rem;
    color: #F5F5F5;
    font-weight: 800;
    transition: border-color 0.5s;
}


@media only screen and (max-width: 1000px) {
    .location-col {
        padding: 0 !important;
        margin: 0 !important;
    }

    .location-btns-row {
        bottom: 25vh;
        width: auto;
        margin-left: calc(-.5 * var(--bs-gutter-x)) !important;
    }
}
