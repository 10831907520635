.invite-title {
    display: flex;
    align-items: center;
    height: 12dvh;
}

.invite-description {
    height: 68dvh;
    align-content: center;
    font-size: 1.5rem;
}

.invite-description .part2 {
    display: flex;
    align-items: center;
    height: 100%;
}

.invite-date{
    height: 20dvh;
    align-content: end;
}

.invite-date .title {
    margin-bottom: 10px;
}

@media only screen and (max-width: 600px) {
}
