.waiting-title-gift {
    height: 10dvh;
    align-content: baseline;
}

.waiting-title-timer {
     height: 5dvh;
     align-content: baseline;
}

.timer-container {
    height: 20dvh;
}

.timer {
    text-align: center;
    font-weight: 600;
    font-style: italic;
    font-size: 3rem;
    height: 15dvh;
}

.gift-list {
    margin-top: 5dvh;
    height: 75dvh;
    text-align: center;
}

.gift-item {
    font-weight: 600;
    font-style: italic;
    font-size: 2rem;
    margin-bottom: 20px;
    margin-left: 5px !important;
}

.program-component {
    text-align: left;
    line-height: 3rem;
}

.program-description {
    font-weight: 600;
    font-style: italic;
    font-size: 1.5rem;
    line-height: 1.7rem;
    color: #757575;
    margin-top: 8px;
}

.program-description-a {
    color: #757575 !important;
}
