a {
    text-decoration: none !important;

}
.just-text {
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    text-align: center;
    font-size: 2rem;
}

.title {
    font-weight: 600;
    font-style: italic;
    font-size: 2.5rem;
    text-align: center;
}

.main-title {
    font-weight: 600;
    font-style: italic;
    font-size: 4rem;
}

.icon {
    width:50px;
}

@media only screen and (max-width: 480px) {
    .just-text  {
        font-family: "Cormorant Garamond", serif;
        font-optical-sizing: auto;
        font-weight: 400;
        font-style: normal;
        text-align: center;
        font-size: 1.5rem;
    }

    .title {
        font-weight: 600;
        font-style: italic;
        font-size: 2.3rem;
        line-height: 3rem;
        text-align: center;
    }

    .main-title {
        font-weight: 600;
        font-style: italic;
        font-size: 3rem;
        line-height: 3.2rem;
    }
}
