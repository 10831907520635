html {
    scroll-behavior: smooth;
}

#root {
    height: 100dvh; width: 100dvw;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
}

.component {
    scroll-snap-align: start; /* Фиксирует каждый блок в начале */
    height: 100dvh; width: 100dvw;
    background-color: #F5F5F5;
    font-family: "Cormorant Garamond", serif;
    overflow: hidden;
}


