.program-title {
    height: 12dvh;
    align-content: baseline;
    align-items: center;
    display: flex;
}

.program-item {
    font-weight: 600;
    font-style: italic;
    font-size: 2rem;
    margin-bottom: 20px;
    text-align: center;
}

.program-description-link {
    font-weight: 900;
    font-size: 1.83rem;
}

.program-component {
    text-align: left;
    line-height: 3rem;
}

.program-description {
    font-weight: 600;
    font-style: italic;
    font-size: 1.5rem;
    line-height: 1.7rem;
    color: #757575;
    margin-top: 8px;
}

.program-description-a {
    color: #757575 !important;
}
