.component-image {
    height: 100dvh;
    padding: 0;
}

.component-text-over-img {
    position: relative;
    color: #fff;
    top: -75dvh;
    left: calc(50% - 150px);
    width: 300px; height: 0;
    font-weight: 600;
}

.component-date-over-img {
    position: relative;
    color: #fff;
    top: -37dvh;
    left: calc(50% - 150px);
    width: 300px; height: 0;
    font-weight: 600;
}


@media only screen and (max-width: 600px) {
    .welcome-col {
        padding: 0 !important;
        margin: 0 !important;
    }

    .component-text-over-img {
        position: relative;
        color: #fff;
        top: -70dvh;
        left: calc(50vw - 150px);
        width: 300px; height: 0;
        font-weight: 600;
    }

    .component-date-over-img {
        position: relative;
        color: #fff;
        top: -33dvh;
        left: calc(50vw - 150px);
        width: 300px; height: 0;
        font-weight: 600;
    }
}
